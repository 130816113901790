<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main mb24px">
        <!-- 搜索 -->
        <div class="search-box">
          <div class="ayl-item" style="width: 280px">
            <span>运输日期</span>
            <el-date-picker
              class="item"
              v-model="date"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
              :picker-options="pickerOptions"
              @change="queryChange"
            >
            </el-date-picker>
          </div>
          <div class="ayl-item" style="width: 200px">
            <span>业务类型</span>
            <el-select
              class="item"
              v-model="query.orderType"
              clearable
              placeholder="请选择"
              @change="queryChange"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="ayl-item" style="width: 210px">
            <el-input
              placeholder="货主/驾驶员/手机号码/省市区"
              v-model="query.queryContent"
              clearable
              @input="queryChange"
            >
            </el-input>
          </div>
          <el-button type="primary" size="mini" @click="onSearch"
            >查询</el-button
          >
          <el-button
            type="warning"
            style="margin-left: 20px"
            @click="independentRecordOrder"
            >自主录单</el-button
          >
        </div>
        <!-- 表格 -->
        <div class="table-box">
          <el-table
            v-loading="loading"
            :header-cell-style="getRowClass"
            :data="tableData"
            style="width: 100%"
          >
            <el-table-column
              prop="orderName"
              show-overflow-tooltip
              width="120px"
              label="货主/驾驶员"
            >
              <template slot-scope="scope">{{
                scope.row.orderName || "- -"
              }}</template>
            </el-table-column>
            <el-table-column
              prop="phone"
              width="120px"
              show-overflow-tooltip
              label="手机号码"
            >
              <template slot-scope="scope">{{
                scope.row.phone || "- -"
              }}</template>
            </el-table-column>
            <el-table-column
              prop="gmtCreate"
              width="160px"
              show-overflow-tooltip
              label="下单/交单时间"
            >
              <template slot-scope="scope">
                <span v-if="!scope.row.gmtCreate">- -</span>
                <span v-else>{{ scope.row.gmtCreate | str2ymd }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderId"
              width="180px"
              show-overflow-tooltip
              label="订单编号"
            >
              <template slot-scope="scope">{{
                scope.row.orderId || "- -"
              }}</template>
            </el-table-column>
            <el-table-column
              prop="orderType"
              align="center"
              width="100px"
              show-overflow-tooltip
              label="业务类型"
            >
              <template slot-scope="scope">
                <span :style="{'color': scope.row.orderType == '直拨'? $config.color_z:scope.row.orderType == '内转'?$config.color_n:$config.color_w}">
                  {{ scope.row.orderType || "- -" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="transportTime"
              width="160px"
              show-overflow-tooltip
              label="预计提货时间"
            >
              <template slot-scope="scope">
                <span v-if="!scope.row.transportTime">- -</span>
                <span v-else>{{ scope.row.transportTime | str2ymd }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="sendAddressDetail"
              show-overflow-tooltip
              label="发货地址"
            >
              <template slot-scope="scope">{{
                scope.row.sendAddressDetail || "- -"
              }}</template>
            </el-table-column>

            <el-table-column
              prop="receiveProvinceName"
              width="200px"
              show-overflow-tooltip
              label="收货地（省市区）"
            >
              <template slot-scope="scope">{{
                scope.row.receiveProvinceName || "- -"
              }}</template>
            </el-table-column>

            <el-table-column
              prop="receiveAddressDetail"
              show-overflow-tooltip
              label="收货地址"
            >
              <template slot-scope="scope">{{
                scope.row.receiveAddressDetail || "- -"
              }}</template>
            </el-table-column>
            <el-table-column
              prop="cargoShipmentRemark"
              show-overflow-tooltip
              label="备注"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.orderType === '外发'">
                  {{ scope.row.cargoShipmentRemark || "- -" }}
                </span>
                <span v-else>
                  {{ scope.row.deliveryRemark || "- -" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120" align="center">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="goRecord(scope.row)"
                  >录单</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="goInvalid(scope.row)"
                  >废单</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top: 20px; text-align: right">
          <el-pagination
            @size-change="mainSizeChange"
            @current-change="mainCurrentChange"
            :current-page.sync="mainCurrentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalNum"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import copySchedulingVue from "../district-management/scheduling/copy-scheduling.vue";
export default {
  data() {
    return {
      nav: [{ name: "订单查询" }],

      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      date: [
        new Date().setTime(
          new Date(new Date().setMonth(new Date().getMonth() - 1))
        ),
        new Date().getTime(),
      ],
      options: this.$enums.OrderType.list,

      query: {
        type: 1,
        orderType: null,
        carBlong: null,
        begin: new Date().setTime(
          new Date(new Date().setMonth(new Date().getMonth() - 1))
        ),
        end: new Date().getTime(),
        queryContent: null,
        currentPage: 1,
        pageSize: 10,
      },

      tableData: [],
      loading: true,
      mainCurrentPage: 1,
      totalNum: 0,
    };
  },

  watch: {
    query: {
      deep: true,
      handler: function (v, ov) {
        this.getDate(this.query);
      },
    },
    date: {
      deep: true,
      handler: function (v, ov) {
        this.query.begin = this.date ? this.date[0] : null;
        this.query.end = this.date ? this.date[1] : null;
      },
    },
  },
  methods: {
    onSearch() {
      this.getDate(this.query);
    },
    clickExport() {},
    mainSizeChange(val) {
      this.mainCurrentPage = 1;
      this.query.currentPage = 1;
      this.query.pageSize = val;
    },
    mainCurrentChange(val) {
      this.query.currentPage = val;
    },
    queryChange() {
      this.mainCurrentPage = 1;
      this.query.currentPage = 1;
    },
    getRowClass({ rowIndex }) {
      if (rowIndex === 0) {
        return "background:#FAFAFA;color:#262626;font-weight:600;";
      } else {
        return "";
      }
    },
    async getDate(val) {
      this.loading = true;
      let data = await this.$api.OrderList(val);
      this.tableData = data.data;
      this.totalNum = data.totalNum;
      this.loading = false;
    },

    /**
     * 自主录单
     */
    // eslint-disable-next-line no-unused-vars
    independentRecordOrder(val) {
      this.$router.push({ path: "/consign-order/independent-record-order" });
    },
    /**
     * 录单
     */
    goRecord(val) {
      try {
        this.$router.push({
          path: "/consign-order/record-order",
          query: {
            orderId: val.orderId,
            orderType: val.orderType,
            orderName: val.orderName,
            phone: val.phone,
            deliveryRemark: val.deliveryRemark,
            cargoShipmentRemark: val.cargoShipmentRemark,
          },
        });
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    /**
     * 废单
     */
    async goInvalid(val) {
      var orderID = val.orderId;
      await this.$confirm(`是否确认废单？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      await this.$api.deleteOrder({
        orderIdList: [orderID],
      });
      this.$notify({
        title: "成功",
        message: "操作成功",
        type: "success",
      });
      this.mainCurrentPage = 1;
      this.query.currentPage = 1;
      this.getDate(this.query);
    },
  },
  mounted() {
    this.getDate(this.query);
  },
  async activated() {
    this.getDate(this.query);
  },
};
</script>

<style lang='css' scoped>
.ayl-item {
  display: flex;
  align-content: center;
  margin-right: 20px;
  width: 20%;
  max-width: 300px;
}
.ayl-item > span {
  margin-right: 10px;
  line-height: 28px;
}
.item {
  flex: 1;
}
.el-button--mini {
  height: 28px;
}
</style>